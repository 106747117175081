<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      id="verify-icon"
      :to="{ name: 'master-user-verification' }"
      class="mr-1"
    >
      <feather-icon
        :badge="countVerifyData"
        badge-classes="badge-info"
        icon="UsersIcon"
        size="21"/>
      <b-tooltip
        triggers="hover"
        target="verify-icon"
        :title="$t('Verify User')"
        :delay="{ show: 50, hide: 50 }"
    /></b-nav-item>

    <b-nav-item id="validate-icon" :to="{ name: 'master-user-validation' }">
      <feather-icon
        :badge="countValidateData"
        badge-classes="badge-info"
        icon="UsersIcon"
        size="21"/>
      <b-tooltip
        triggers="hover"
        target="validate-icon"
        :title="$t('Validate User')"
        :delay="{ show: 50, hide: 50 }"
    /></b-nav-item>
  </b-navbar-nav>
</template>

<script>
import { BNavbarNav, BNavItem, BTooltip } from 'bootstrap-vue'
import axios from '@axios'
import vertical from '@/navigation/vertical'
import { ref, reactive, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BNavbarNav,
    BNavItem,
    BTooltip,
  },
  setup() {
    // VERIFICATION
    const countVerifyData = ref(0)

    const verifyBadge = reactive(
      vertical
        .find(item => item.title === 'Users')
        ?.children.find(item => item.route === 'master-user-verification'),
    )

    watch(
      () => store.state.socket.verificationRequest,
      function(val) {
        countVerifyData.value = val
        verifyBadge.tag = val
      },
    )

    const getVerify = () => {
      axios.get('admin/verify/count').then(response => {
        const data = response.data.count
        countVerifyData.value = data
        verifyBadge.tag = data

        store.commit('socket/UPDATE_VERIFICATION_REQUEST', data)
      })
    }
    getVerify()

    // VALIDATION
    const countValidateData = ref(0)

    const validateBadge = reactive(
      vertical
        .find(item => item.title === 'Users')
        ?.children.find(item => item.route === 'master-user-validation'),
    )

    watch(
      () => store.state.socket.validationRequest,
      function(val) {
        countValidateData.value = val
        validateBadge.tag = val
      },
    )

    const getValidation = () => {
      axios
        .get('admin/validate/count')
        .then(response => {
          const data = response.data.count
          countValidateData.value = data
          validateBadge.tag = data

          store.commit('socket/UPDATE_VALIDATION_REQUEST', data)
        })
        .catch(() => {})
    }
    getValidation()

    return {
      countVerifyData,
      countValidateData,
    }
  },
}
</script>
