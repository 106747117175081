<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-sm-flex"
    >
      <notification-update />
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
// import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
// import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import NotificationUpdate from '@/@core/layouts/components/app-navbar/components/NotificationUpdate/NotificationUpdate.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    Locale,
    // SearchBar,
    DarkToggler,
    // NotificationDropdown,
    UserDropdown,
    NotificationUpdate,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
