<template>
  <layout-vertical>

    <router-view />

    <!-- <div
      slot="vertical-menu-header"
      class="d-flex align-items-center h-100"
      toggleCollapsed
      collapseTogglerIcon
    >
      <h1 class="font-medium-5 mb-0 text-primary">
        Nikah Saja
      </h1>
    </div> -->

    <template
      #navbar="{ toggleVerticalMenuActive }"
    >
      <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div
      slot="footer"
    >
      <p class="mb-0">
        &copy; Nikah Saja {{ new Date().getFullYear() }}
      </p>
    </div>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import AppNavbarVerticalLayout from '../components/app-navbar/AppNavbarVerticalLayout.vue'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    AppNavbarVerticalLayout,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
